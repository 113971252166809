
const SET_AUTENTICADO = (state, autenticado) => {
  state.autenticado = autenticado;
}

const SET_USUARIO = (state, usuario) => {
  state.usuario = usuario;
}

export default{
  SET_AUTENTICADO,
  SET_USUARIO,
}