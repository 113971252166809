import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import shared from "@/store/shared";
import auth from "@/store/auth";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[
      createPersistedState({
        storage: window.sessionStorage,
    })
  ],
  state: {
    sidebar_status: null,
    sidebar_status_mini: null,
  },
  getters: {
    sidebar_status: state => state.sidebar_status,
    sidebar_status_mini: state => state.sidebar_status_mini,
  },
  mutations: {
    SIDEBAR_TOGGLE(state, form){
      state.sidebar_status = !state.sidebar_status;
    },
    SIDEBAR_TOGGLE_MINI(state, form){
      state.sidebar_status_mini = !state.sidebar_status_mini;
    },
  },
  actions: {
  },
  modules: {
    shared,
    auth
  }
})
