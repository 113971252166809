import mutations from "./mutations";
import getters from "./getters";

const state = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}