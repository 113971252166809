import router from "@/router"

const signIn = async ({commit}, payload) => {
  commit('SET_USUARIO', payload.usuario);
  commit('SET_AUTENTICADO', true);

  router.push({ name:'Home' });
}

const signInAdmin = async ({commit}, payload) => {
  commit('SET_USUARIO', payload.usuario);
  commit('SET_AUTENTICADO', true);

  router.push({ name:'AdminHome' });
}

const signOut = () => {
  window.sessionStorage.clear();

  router.push({ name:'Login' }).catch(()=>{});
}

const signOutAdmin = () => {
  window.sessionStorage.clear();

  router.push({ name:'LoginAdmin' }).catch(()=>{});
}

export default {
  signIn,
  signOut,
  signInAdmin,
  signOutAdmin,
}