import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/css/main.css';
import '@babel/polyfill';
import Notifications from "vue-notification";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue2Filters from 'vue2-filters';
import './assets/css/main.css';
import VueMask from 'v-mask';

Vue.config.productionTip = false
Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.use(VueMask);

Vue.use(Vue2Filters, {
  currency: {
    symbol: "R$",
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false
  }
});

const moment = require('moment');
require('moment/locale/pt-br');

Vue.use(require('vue-moment'), {
  moment
});

new Vue({
  router,
  store,
  vuetify,
  Notifications,
  render: function (h) { return h(App) }
}).$mount('#app')
