import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import goTo from "vuetify/es5/services/goto";

//Middlewares
import Auth from './middleware/Auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: "/guest",
  },
  {
    path: '/guest',
    component: () => import(/* webpackChunkName: "LayoutGuest" */ '../views/layouts/LayoutGuest.vue'),
    children: [
      {
        path: '/guest',
        redirect: "/guest/login",
      },
      {
        path: '/guest/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
      },
      {
        path: '/guest/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/ForgotPassword.vue')
      },
      {
        path: '/guest/reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
      },
      {
        path: "*",
        component: () => import("@/views/NotFound.vue"),
      }
    ],
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "LayoutAuth" */ '../views/layouts/LayoutAuth.vue'),
    children: [
      {
        path: '/app',
        redirect: "/app/home",
      },
      {
        path: '/app/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/home/Index.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/my-profile',
        name: 'MyProfile',
        component: () => import(/* webpackChunkName: "MyProfile" */ '../views/myProfile/MyProfile.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/profiles',
        name: 'ProfilesIndex',
        component: () => import(/* webpackChunkName: "ProfilesIndex" */ '../views/profiles/ProfilesIndex.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/profiles/:profile/edit',
        name: 'ProfilesEdit',
        component: () => import(/* webpackChunkName: "ProfilesEdit" */ '../views/profiles/ProfilesEdit.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      //ADMIN
      {
        path: '/app/usuarios',
        name: 'UsuariosIndex',
        component: () => import(/* webpackChunkName: "UsuariosIndex" */ '../views/usuarios/UsuariosIndex.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/usuarios/create',
        name: 'UsuariosCreate',
        component: () => import(/* webpackChunkName: "UsuariosCreate" */ '../views/usuarios/UsuariosCreate.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/usuarios/:user/edit',
        name: 'UsuariosEdit',
        component: () => import(/* webpackChunkName: "UsuariosEdit" */ '../views/usuarios/UsuariosEdit.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/usuarios/:user',
        name: 'UsuariosShow',
        component: () => import(/* webpackChunkName: "UsuariosShow" */ '../views/usuarios/UsuariosShow.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/roles',
        name: 'RolesIndex',
        component: () => import(/* webpackChunkName: "RolesIndex" */ '../views/roles/RolesIndex.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/roles/create',
        name: 'RolesCreate',
        component: () => import(/* webpackChunkName: "RolesCreate" */ '../views/roles/RolesCreate.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/roles/:role/edit',
        name: 'RolesEdit',
        component: () => import(/* webpackChunkName: "RolesEdit" */ '../views/roles/RolesEdit.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: '/app/roles/:role',
        name: 'RolesShow',
        component: () => import(/* webpackChunkName: "RolesShow" */ '../views/roles/RolesShow.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      //
      {
        path: '/app/unauthorized',
        name: 'Unauthorized',
        component: () => import(/* webpackChunkName: "Unauthorized" */ '../views/auth/Unauthorized.vue'),
        meta: { 
          middleware: Auth,
        },
      },
      {
        path: "*",
        component: () => import("@/views/NotFound.vue"),
        meta: { 
          middleware: Auth,
        },
      }
    ]
  },
  {
    path: "*",
    name: "Notfound",
    component: () => import("@/views/NotFound.vue")
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if(!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store
  };

  return middleware({
    ...context
  });
});

export default router
